.design-tools {
  width: 100%;
  min-width: 0;
  padding: var(--spacing-base);
  box-sizing: border-box;
}

.design-tools h2 {
  color: var(--charcoal);
  font-size: 1.125rem;
  margin-bottom: 1rem;
  text-align: center;
}

.tool-section {
  width: 100%;
  box-sizing: border-box;
  background: var(--cream-bg);
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: var(--border-radius);
}

.tool-section h3 {
  color: var(--charcoal);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid var(--cream-bg);
}

.tool-button {
  width: 100%;
  padding: 10px;
  background-color: #8BA888;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tool-button:hover {
  background-color: #7a9377;
}

.next-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--sage);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.next-button:hover {
  background-color: var(--sage-hover);
  transform: translateY(-1px);
}

.text-tool {
  display: flex;
  gap: 10px;
}

.text-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.shirt-color-selector,
.color-grid {
  /* Remove these styles */
}

.text-controls {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.font-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.text-color-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.color-swatch {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.color-swatch:hover {
  transform: scale(1.1);
}

.text-style-controls {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  background: white;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.style-button {
  width: 36px;
  height: 36px;
  border: 1px solid var(--cream-bg);
  border-radius: var(--border-radius);
  background: white;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--charcoal);
}

.style-button:hover {
  background: var(--cream-bg);
}

.style-button.active {
  background: var(--sage);
  color: white;
  border-color: var(--sage);
}

.color-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: var(--charcoal);
}

.color-picker-container {
  position: relative;
  width: 100%;
}

.color-preview {
  width: 100%;
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.color-picker-popover {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
  z-index: 2000;
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.color-picker-wrapper {
  position: relative;
  z-index: 1001;
  background: white;
  padding: 8px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

/* Ensure the color picker is visible */
.react-colorful {
  width: 200px !important;
  height: 200px !important;
} 