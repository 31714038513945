/* Common variables */
:root {
  --cream-bg: #f4f1ea;
  --charcoal: #2b2b2b;
  --sage: #87997d;
  --sage-hover: #758a6d;
  --spacing-base: 1rem;
  --spacing-large: 1.5rem;
  --border-radius: 0.25rem;
  --border-radius-large: 0.75rem;
}

/* Common button styles */
.tool-button, .next-button, .back-button, .toggle-button {
  background-color: var(--sage);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.tool-button:hover, .next-button:hover, .back-button:hover, .toggle-button:hover {
  background-color: var(--sage-hover);
}

/* Headings */
h2, h3 {
  color: var(--charcoal);
  margin-bottom: 15px;
}

.shirt-designer {
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  padding: var(--spacing-large);
  background-color: var(--cream-bg);
  min-width: 320px;
  overflow-x: hidden;
}

.designer-layout {
  display: grid;
  grid-template-columns: minmax(0, 75%) minmax(0, 25%);
  gap: 50px;
  align-items: start;
  width: 100%;
}

.canvas-section {
  width: 100%;
  min-width: 0;
  background: white;
  border-radius: var(--border-radius-large);
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar {
  width: 100%;
  min-width: 0;
  overflow: hidden;
  border-radius: var(--border-radius-large);
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
}

/* Start responsive adjustments much earlier */
@media (max-width: 100rem) { /* 1600px - start earlier */
  .designer-layout {
    grid-template-columns: minmax(0, 70%) minmax(0, 30%);
  }
}

@media (max-width: 85rem) { /* 1360px */
  .designer-layout {
    grid-template-columns: minmax(0, 65%) minmax(0, 35%);
  }
}

@media (max-width: 64rem) { /* 1024px */
  .designer-layout {
    grid-template-columns: 1fr; /* Stack vertically */
    gap: 50px; /* Maintain consistent gap */
  }
  
  .shirt-designer {
    padding: var(--spacing-base);
  }
  
  .sidebar {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 48rem) { /* 768px */
  .designer-layout {
    gap: 30px;
  }
  
  .canvas-section,
  .order-details {
    padding: var(--spacing-base);
  }
}

.order-details {
  width: 100%;
  background: white;
  border-radius: var(--border-radius-large);
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  padding: var(--spacing-large);
} 