/* Main container */
.checkout-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  color: #2b2b2b;
  background-color: #f4f1ea;
}

.checkout-header h1 {
  color: #2b2b2b;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  font-family: "Times New Roman", serif;
  font-size: 2.5em;
}

.checkout-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  width: 100%;
  max-width: 100%;
}

/* Order Summary Section */
.order-summary-section, 
.what-happens-next, 
.payment-form-section {
  background: #f8f5ee;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #d3d0c9;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.what-happens-next h2, 
.payment-form-section h2 {
  color: #2b2b2b;
  margin-bottom: 20px;
  border-bottom: 2px solid #2b2b2b;
  padding-bottom: 10px;
  font-weight: 600;
  font-family: "Times New Roman", serif;
}

.order-summary-section h3 {
  color: #2b2b2b;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: "Times New Roman", serif;
}

/* 3-column layout for order summary */
.order-summary-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin-bottom: 25px;
}

.order-summary-column {
  min-height: 240px;
}

/* Artwork Preview Column */
.artwork-column {
  border-right: 1px solid #eee;
  padding-right: 20px;
}

.artwork-container {
  height: 240px;
  width: 240px;
  margin: 0 auto;
}

.artwork-preview-box {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.artwork-preview-box p {
  margin-bottom: 10px;
  font-weight: 500;
  color: #555;
}

.artwork-preview-box img {
  max-width: 100%;
  max-height: 180px;
  object-fit: contain;
  border-radius: 4px;
}

.no-artwork {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  border: 1px dashed #ccc;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #888;
}

/* Order Details Column */
.details-column {
  border-right: 1px solid #eee;
  padding-right: 20px;
}

.details-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.detail-label {
  font-weight: 600;
  color: #555;
  margin-bottom: 4px;
}

.detail-value {
  color: #333;
}

/* Size Breakdown Column */
.size-table-container {
  height: 240px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 6px;
}

.size-table {
  width: 100%;
  border-collapse: collapse;
}

.size-table th,
.size-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.size-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #444;
}

.size-table tfoot td {
  font-weight: 600;
  border-top: 2px solid #ddd;
  background-color: #f9f9f9;
}

/* Pricing Summary */
.pricing-summary {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #8BA888;
}

.pricing-table {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  border-collapse: collapse;
}

.pricing-table td {
  padding: 10px;
  text-align: right;
}

.pricing-table td:first-child {
  text-align: left;
  font-weight: 500;
  color: #555;
}

.pricing-table tr.pricing-total {
  font-size: 1.2em;
  font-weight: 700;
  color: #333;
  border-top: 2px solid #ddd;
}

.pricing-table tr.pricing-total td {
  padding-top: 15px;
}

/* What Happens Next Section */
.what-happens-next {
  background-color: #f9f9f9;
}

.what-happens-next p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #444;
}

.what-happens-next ul {
  padding-left: 20px;
  margin-bottom: 15px;
}

.what-happens-next li {
  margin-bottom: 10px;
  line-height: 1.6;
  color: #444;
}

/* Form Styling - Fixed alignment issues */
.payment-form-section {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  padding: 25px;
}

.payment-form-section h2 {
  margin-bottom: 25px;
  width: 100%;
}

.payment-form-section form {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.form-row {
  margin-bottom: 24px;
  width: 100%;
  max-width: 100%;
  display: block;
  padding: 0;
}

.form-row.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  position: relative;
}

/* Ensure fields take up all available space */
.form-group input, 
.form-group textarea,
.card-element-container {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  height: 48px;
}

/* Make sure the payment form aligns with the section header */
.payment-form-section form,
.payment-form-section .form-row,
.payment-form-section h2 {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

/* Ensure the single-row elements (credit card, notes) take full width */
.form-row:not(.two-columns) .form-group {
  width: 100%;
  max-width: 100%;
}

/* Ensure the credit card element takes full width */
.card-element-container {
  width: 100%;
  max-width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #444;
  width: 100%;
  font-size: 16px;
}

.form-group input:focus, 
.form-group textarea:focus,
.card-element-container:focus-within {
  outline: none;
  border-color: #2b2b2b;
  box-shadow: 0 0 0 2px rgba(43, 43, 43, 0.1);
}

/* Card Element */
.card-element-container {
  display: flex;
  align-items: center;
  background-color: white;
  height: 48px;
}

.card-error {
  color: #fa755a;
  margin-top: 8px;
  font-size: 14px;
  position: absolute;
  bottom: -22px;
}

/* Make form actions take full width with better spacing */
.form-actions {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 36px;
  width: 100%;
}

.back-button, 
.place-order-button {
  padding: 14px 28px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button {
  background-color: #6c757d;
  color: white;
}

.place-order-button {
  background-color: #8BA888;
  color: white;
  padding: 12px 30px;
}

.back-button:hover {
  background-color: #5a6268;
}

.place-order-button:hover {
  background-color: #7a9377;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 992px) {
  .order-summary-grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .sizes-column {
    grid-column: span 2;
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 10px;
  }
  
  .artwork-column {
    border-right: 1px solid #eee;
  }
  
  .details-column {
    border-right: none;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .order-summary-grid {
    grid-template-columns: 1fr;
  }
  
  .order-summary-column {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    border-right: 0;
  }
  
  .artwork-column,
  .details-column {
    border-right: none;
    padding-right: 0;
  }
  
  .sizes-column {
    grid-column: span 1;
  }
  
  .form-row.two-columns {
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  .form-actions {
    flex-direction: column;
    gap: 15px;
  }
  
  .back-button, 
  .place-order-button {
    width: 100%;
  }
  
  .artwork-container {
    margin: 0 auto;
  }
}

/* Specific fixes for alignment issues */

/* Ensure the payment form section has no extra padding that might affect alignment */
.payment-form-section {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  padding: 25px;
}

/* Target the form directly to ensure it takes full width */
.payment-form-section form {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Ensure the two-column rows take the full width of their container */
.form-row.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 24px;
}

/* Make sure each form group inside two-column rows takes up full space */
.form-row.two-columns .form-group {
  width: 100%;
  box-sizing: border-box;
}

/* Ensure inputs take full width of their containers */
.form-group input, 
.form-group textarea,
.card-element-container {
  width: 100%;
  box-sizing: border-box;
}

/* Remove any potential overflow constraints */
.checkout-page,
.checkout-container,
.payment-form-section,
.payment-form-section form,
.form-row,
.form-group {
  overflow: visible;
}

/* Add a specific fix for the first row's inputs if needed */
.payment-form-section form .form-row:first-of-type input {
  width: 100%;
  box-sizing: border-box;
}

/* Discount Code Styling */
.discount-code-row {
  border-top: 1px solid #eee;
}

.discount-code-container {
  width: 100%;
  padding: 10px 0;
}

.discount-input-container {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
}

.discount-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 150px;
  font-family: 'Courier New', Courier, monospace;
}

.apply-discount-button {
  padding: 8px 12px;
  background-color: #8BA888;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
  font-family: 'Courier New', Courier, monospace;
}

.apply-discount-button:hover:not(:disabled) {
  background-color: #7a9377;
}

.apply-discount-button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

.applied-discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.discount-label {
  font-size: 14px;
  color: #4caf50;
  font-weight: 600;
}

.remove-discount-button {
  background: none;
  border: none;
  color: #d32f2f;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  padding: 4px 8px;
  font-family: 'Courier New', Courier, monospace;
}

.remove-discount-button:hover {
  color: #b71c1c;
}

.discount-amount-row td {
  color: #4caf50;
  font-weight: 600;
}

/* Ensure responsive design for discount code section */
@media (max-width: 768px) {
  .discount-input-container {
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
  }
  
  .discount-input {
    width: 100%;
  }
}

.rush-fee-note {
  font-size: 0.85em;
  color: #f0ad4e;
  font-style: italic;
  margin-left: 5px;
}