.order-details {
  width: 100%;
  min-width: 0;
  padding: var(--spacing-base);
  box-sizing: border-box;
}

.order-details h2 {
  color: var(--charcoal);
  font-size: 1.125rem;
  margin-bottom: 1rem;
  text-align: center;
}

.details-section {
  width: 100%;
  box-sizing: border-box;
  background: var(--cream-bg);
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: var(--border-radius);
}

.details-section h3 {
  color: var(--charcoal);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid var(--cream-bg);
}

.size-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.size-input-group {
  background-color: white;
  padding: 0.75rem;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.size-input-group label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.size-input-group input {
  width: 50px;
  height: 36px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 4px;
  -moz-appearance: textfield;
}

/* Remove spinner buttons for Chrome/Safari/Edge */
.size-input-group input::-webkit-outer-spin-button,
.size-input-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.total-quantity {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  color: #666;
}

.delivery-selection select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  background: white;
  cursor: pointer;
}

.navigation-buttons {
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
  width: 100%;
}

.back-button,
.next-button {
  flex: 1;
  height: 2.75rem;
  padding: 0;
  border: none;
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0;
}

.back-button {
  background: var(--cream-bg);
  color: var(--charcoal);
}

.back-button:hover {
  background: #e5e2db;
}

.next-button {
  background: var(--sage);
  color: white;
}

.next-button:hover {
  background: var(--sage-hover);
}

/* Update responsive layout */
@media (max-width: 768px) {
  .navigation-buttons {
    flex-direction: column;
  }
  
  .back-button,
  .next-button {
    width: 100%;
  }
}

/* Add these styles */
.pricing-breakdown {
  background: white;
  padding: 0.75rem;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.price-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.125rem 0;
  font-size: 0.875rem;
}

.price-row span:first-child {
  color: var(--charcoal);
}

.price-row.total {
  border-top: 2px solid var(--cream-bg);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}

.date-selection {
  background: white;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.date-input {
  width: calc(100% - 1rem);
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.date-legend {
  display: flex;
  gap: 1rem;
  font-size: 0.75rem;
  color: var(--charcoal);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.color-dot.red {
  background-color: #ff6b6b;
}

.color-dot.yellow {
  background-color: #ffd93d;
}

/* Style the date picker dropdown */
.date-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

/* Style the dates based on availability */
.date-input[type="date"] {
  position: relative;
  cursor: pointer;
}

/* Add these styles to modify the calendar appearance */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* DatePicker customization */
.custom-calendar {
  font-family: inherit;
  border-radius: var(--border-radius);
  border: 1px solid #ddd;
}

.unavailable-date {
  background-color: #ff6b6b !important;
  color: white !important;
  border-radius: 0.2em;
}

.rush-date {
  background-color: #ffd93d !important;
  border-radius: 0.2em;
}

.standard-date {
  background-color: white;
}

/* Ensure the datepicker wrapper doesn't overflow */
.react-datepicker-wrapper {
  width: 100%;
  display: block;
}

/* Make the calendar popup position better */
.react-datepicker-popper {
  z-index: 2;
}

.rush-fee {
  color: #f0ad4e;  /* warm orange color */
  font-weight: 500;
} 