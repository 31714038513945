.design-canvas-container {
  position: relative;
  width: 100%;
  aspect-ratio: 3/4;
  overflow: hidden;
  background: white;
  margin: 0; /* Ensure no extra margins */
}

/* Target all canvas elements created by Fabric.js */
.canvas-container,
.canvas-container canvas,
.upper-canvas,
.lower-canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Remove any default canvas styling */
canvas {
  display: block !important;
}

/* Base canvas container */
.canvas-container {
  position: absolute !important;
  width: 450px !important;
  height: 600px !important;
  transform-origin: 0 0;
  margin: 0 !important; /* Ensure no extra margins */
}

/* Both upper and lower canvas should match canvas-container exactly */
.upper-canvas,
.lower-canvas {
  width: 450px !important;
  height: 600px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.zoom-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toggle-button {
  background-color: var(--sage);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: var(--sage-hover);
}

/* Update the resize function in DesignCanvas.js */ 